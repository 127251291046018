import { EVENTS_STORE_MODULE_NAME, EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';

import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getEventOwnedBy } from '@/views/apps/events/utils/event-utils';
import { getImageResource } from '@/@core/utils/image-utils';
import { EventType } from '@copernicsw/community-common';
import { enumToInvertedObject } from '@core/utils/utils';

export default {
  name: 'EventDetailsMixin',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return { event: null };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    translatedDescription() {
      return this.event ? translateTranslationTable(this.locale, this.event.description) : '';
    },
    translatedTitle() {
      return this.event ? translateTranslationTable(this.locale, this.event.title) : '';
    },
    isOver() {
      const endDate = this.event.endDate || this.$moment(this.event.startDate).endOf('day');
      return this.$moment(Date.now()).isAfter(endDate);
    },
    ownedBy() {
      return this.event.ownedByCommunity || this.event.ownedByUser;
    },
    ownedByLogo() {
      return getImageResource(this.ownedBy.logoURL);
    },
    eventImage() {
      return getImageResource(this.event.thumbnailURL);
    },
    typeEvent() {
      return enumToInvertedObject(EventType)[this.event.type];
    },
  },
  async created() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
  },
  methods: {
    handleEventLoaded(event) {
      this.event = event;
    },
    async fetchEvent() {
      const response = (await this.$store.dispatch(EVENTS_ACTIONS.fetchEventList, { 
        visibleOncommunitySlug: this.$route.params.communityId,
        eventKeys: this.id, 
      })).data?.data[0];

      if(response == null){
        return (await this.$store.dispatch(EVENTS_ACTIONS.fetchEventList, { 
          communitySlug: this.$route.params.communityId,
          eventKeys: this.id, 
        })).data?.data[0];
      }else{
        return response;
      }
    },
    async EditEvent() {
      const event = {
        id: this.$route.params.id,
        title: this.updateEvent.title,
        description: this.updateEvent.description,
        startDate: new Date(this.updateEvent.startDate).getTime(),
        endDate: this.updateEvent.endDate ? new Date(this.updateEvent.endDate).getTime() : null,
        allDay: false,
        location: [],
      };
      await this.$store.dispatch(
        EVENTS_ACTIONS.editEvent,
        { event },
      );
      this.updateBreadcrumbs();
    }, 
  },
};
