<template>
  <model-details class="event-details" :fetch-model="fetchEvent" @model-loaded="handleEventLoaded">
    <room-details v-if="event" :room="room" :skip-welcome="skipWelcome" />
  </model-details>
</template>

<script>
import { isMeeting } from '@copernicsw/community-common';

import ModelDetails from '@core/components/model-details/ModelDetails.vue';
import RoomDetails from '@/views/apps/rooms/components/room-details/RoomDetails.vue';
import EventDetailsMixin from '@/views/apps/events/mixins/EventDetailsMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    ModelDetails,
    RoomDetails,
  },
  mixins: [EventDetailsMixin],
  computed: {
    room() {
      return {
        key: this.event.key,
        name: translateTranslationTable(this.$store.getters.currentLocale, this.event.title) || (Object.values(this.event.title))[0],
        description: this.translatedDescription,
        backgroundURL: this.event.thumbnailURL,
        openingTime: this.event.startDate,
        isEvent: true,
        room: this.event.room,
        closingTime: this.event.endDate
          || (
            this.event.allDay
              ? this.$moment(this.event.startDate).endOf('day').valueOf()
              : this.$moment(this.event.startDate).add(30, 'm').valueOf()
          ),
        identifier: `event-room-${this.event.key}`,
        startURL: this.event.startURL,
      };
    },
    skipWelcome() {
      return true;
    },
  },
};
</script>
